<template>
  <header id="header" class="u-header">
    <div class="u-header__section py-1">
      <div id="logoAndNav" class="container">
        <nav
          class="
            js-mega-menu
            navbar navbar-expand-md
            u-header__navbar u-header__navbar--no-space
          "
        >
          <div class="w-lg-auto">
            <a
              class="navbar-brand pt-md-0"
              href="/#/home"
              target="_parent"
              aria-label="HecoInfo"
            >
              <img
                id="logo-header"
                width="140"
                height="50"
                src="../../assets/images/home/logo.png"
                alt=""
              />
            </a>
          </div>
          <div>
            <button
              type="button"
              class="navbar-toggler btn u-hamburger"
              aria-label="Toggle navigation"
              aria-expanded="false"
              aria-controls="navBar"
              data-toggle="collapse"
              data-target="#navBar"
            >
              <span id="hamburgerTrigger" class="u-hamburger__box">
                <span class="u-hamburger__inner"></span>
              </span>
            </button>
          </div>

          <div class="d-flex flex-column w-100">
            <div
              id="navBar"
              class="
                collapse
                navbar-collapse
                u-header__navbar-collapse
                order-md-2
              "
            >
              <ul class="navbar-nav u-header__navbar-nav px-2 px-md-0">
                <li
                  id="LI_default"
                  class="nav-item u-header__nav-item"
                  :class="{ active: currentPage == 'Home' }"
                  data-event="hover"
                  data-animation-in="slideInUp"
                  data-animation-out="fadeOut"
                >
                  <a
                    class="nav-link u-header__nav-link"
                    href="/#/home"
                    aria-haspopup="true"
                    aria-expanded="false"
                    aria-labelledby="pagesSubMenu"
                    >Home</a
                  >
                </li>

                <li
                  id="LI_blockchain"
                  class="nav-item hs-has-sub-menu u-header__nav-item"
                  data-event="hover"
                  :class="{
                    active: currentPage == 'Txs' || currentPage == 'Accounts',
                  }"
                  data-animation-in="slideInUp"
                  data-animation-out="fadeOut"
                >
                  <a
                    id="blockchainMegaMenu"
                    class="
                      nav-link
                      u-header__nav-link u-header__nav-link-toggle
                    "
                    href="javascript:;"
                    aria-haspopup="true"
                    aria-expanded="false"
                    aria-labelledby="pagesSubMenu"
                    >Blockchain</a
                  >
                  <ul
                    id="blockchainSubMenu"
                    class="
                      hs-sub-menu
                      u-header__sub-menu u-header__sub-menu--spacer
                    "
                    aria-labelledby="blockchainMegaMenu"
                    style="min-width: 230px"
                  >
                    <li
                      id="LI_accountall"
                      :class="{
                        active: currentPage == 'Accounts',
                      }"
                    >
                      <a
                        href="/#/accounts"
                        class="nav-link u-header__sub-menu-nav-link"
                        >Top Accounts</a
                      >
                    </li>
                    <li class="dropdown-divider"></li>
                    <li
                      id="LI12"
                      :class="{
                        active: currentPage == 'Txs',
                      }"
                    >
                      <a
                        href="/#/txs"
                        class="nav-link u-header__sub-menu-nav-link"
                        >View Txns</a
                      >
                    </li>

                    <li class="dropdown-divider"></li>
                    <li
                      id="LI12"
                      :class="{
                        active: currentPage == 'TxsInternal',
                      }"
                    >
                      <a
                        href="/#/txsInternal"
                        class="nav-link u-header__sub-menu-nav-link"
                        >View Internal Txns</a
                      >
                    </li>
                  </ul>
                </li>

                <li
                  class="nav-item hs-has-sub-menu u-header__nav-item"
                  data-event="hover"
                  :class="{
                    active:
                      currentPage == 'TxsToken' || currentPage == 'Tokens',
                  }"
                  data-animation-in="slideInUp"
                  data-animation-out="fadeOut"
                >
                  <a
                    id="testTokensMegaMenu"
                    class="
                      nav-link
                      u-header__nav-link u-header__nav-link-toggle
                    "
                    href="javascript:;"
                    aria-haspopup="true"
                    aria-expanded="false"
                    aria-labelledby="testTokensSubMenu"
                    >Tokens</a
                  >
                  <ul
                    id="testTokensSubMenu"
                    class="
                      hs-sub-menu
                      u-header__sub-menu u-header__sub-menu--spacer
                    "
                    aria-labelledby="testTokensMegaMenu"
                    style="min-width: 230px"
                  >
                    <li
                      :class="{
                        active:
                          currentPage == 'Tokens' && (erc == 2 || erc == 20),
                      }"
                    >
                      <a
                        class="nav-link u-header__sub-menu-nav-link"
                        href="/#/tokens?num=2"
                        >RCH-20 Tokens</a
                      >
                    </li>
                    <li
                      :class="{
                        active:
                          currentPage == 'TxsToken' && (erc == 2 || erc == 20),
                      }"
                    >
                      <a
                        class="nav-link u-header__sub-menu-nav-link"
                        href="/#/txsToken?num=2"
                        >View RCH-20 Transfers</a
                      >
                    </li>
                    <li class="dropdown-divider"></li>

                    <li
                      :class="{ active: currentPage == 'Tokens' && erc == 721 }"
                    >
                      <a
                        class="nav-link u-header__sub-menu-nav-link"
                        href="/#/tokens?num=721"
                        >RCH-721 Tokens</a
                      >
                    </li>
                    <li
                      :class="{
                        active: currentPage == 'TxsToken' && erc == 721,
                      }"
                    >
                      <a
                        class="nav-link u-header__sub-menu-nav-link"
                        href="/#/txsToken?num=721"
                        >View RCH-721 Transfers</a
                      >
                    </li>
                    <li class="dropdown-divider"></li>

                    <li
                      :class="{ active: currentPage == 'Tokens' && erc == 1 }"
                    >
                      <a
                        class="nav-link u-header__sub-menu-nav-link"
                        href="/#/tokens?num=1"
                        >Tokens</a
                      >
                    </li>
                    <li
                      :class="{ active: currentPage == 'TxsToken' && erc == 1 }"
                    >
                      <a
                        class="nav-link u-header__sub-menu-nav-link"
                        href="/#/txsToken?num=1"
                        >View Token Transfers</a
                      >
                    </li>
                  </ul>
                </li>

                <li
                  class="nav-item hs-has-sub-menu u-header__nav-item"
                  data-event="hover"
                  :class="{
                    active:
                      currentPage == 'ContractVerify' ||
                      currentPage == 'Contracts',
                  }"
                  data-animation-in="slideInUp"
                  data-animation-out="fadeOut"
                >
                  <a
                    id="testTokensMegaMenu"
                    class="
                      nav-link
                      u-header__nav-link u-header__nav-link-toggle
                    "
                    href="javascript:;"
                    aria-haspopup="true"
                    aria-expanded="false"
                    aria-labelledby="testTokensSubMenu"
                    >Contract</a
                  >
                  <ul
                    id="testTokensSubMenu"
                    class="
                      hs-sub-menu
                      u-header__sub-menu u-header__sub-menu--spacer
                    "
                    aria-labelledby="testTokensMegaMenu"
                    style="min-width: 230px"
                  >
                    <li
                      id="LI2"
                      :class="{ active: currentPage == 'Contracts' }"
                    >
                      <a
                        class="nav-link u-header__sub-menu-nav-link"
                        href="/#/contracts"
                        >View Contracts</a
                      >
                    </li>
                    <li class="dropdown-divider"></li>

                    <li
                      id="LI18"
                      :class="{ active: currentPage == 'ContractVerify' }"
                    >
                      <a
                        class="nav-link u-header__sub-menu-nav-link"
                        href="/#/contract_verify"
                        >Publish & Verify</a
                      >
                    </li>
                  </ul>
                </li>

                <li
                  :style="{ display: apiDocsUrl ? block : none }"
                  id="LI_API_DOCS"
                  class="nav-item u-header__nav-item"
                  :class="{ active: currentPage == 'DOCS' }"
                  data-event="hover"
                  data-animation-in="slideInUp"
                  data-animation-out="fadeOut"
                >
                  <a
                    class="nav-link u-header__nav-link"
                    :href="apiDocsUrl"
                    target="_blank"
                    aria-haspopup="true"
                    aria-expanded="false"
                    aria-labelledby="pagesSubMenu"
                    >API Docs</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  </header>
</template>

<script>
import { onBeforeRouteUpdate } from "vue-router";
import { reactive, toRefs } from "vue";
import route from "../../router/index";
import { PostNet, PostNetUrl } from "../../../public/assets/js/myRequest.js";

import { importJS } from "../../../src/assets/js/utils.js";
export default {
  name: "Header",
  data() {
    return { apiDocsUrl: "" };
  },
  setup() {
    const state = reactive({
      currentPage: "Home",
      erc: 1,
    });
    onBeforeRouteUpdate((to) => {
      if (to.query && to.query.num) {
        state.erc = to.query.num;
      }
      if (to.name && to.name.length > 0) {
        state.currentPage = to.name;
        // console.log(to.name);
      } else {
        state.currentPage = "Home";
      }
    });
    var name = route.currentRoute.value.name;
    // console.log(name);
    if (name && name.length > 0) {
      state.currentPage = name;
    } else {
      state.currentPage = "Home";
    }
    return {
      ...toRefs(state),
    };
  },
  mounted() {
    importJS("assets/js/header/nav.js");
    if (this.$route.query && this.$route.query.num) {
      this.erc = this.$route.query.num;
    }

    PostNet.PostWith(
      PostNetUrl.apiDocsUrl,
      (info) => {
        this.apiDocsUrl = info.data;
        console.dir(this.apiDocsUrl);
      },
      (res) => {},
      {}
    );
  },
  methods: {},
};
</script>
<style lang="scss" scoped></style>
